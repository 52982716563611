.table {
  .v-align-m {
    vertical-align: middle;
  }

  .action_buttons {
    text-align:right;
    white-space: nowrap;
    width: 1%;
  }
}

.main-header .logo {
  font-size: 22px !important;
}

.img-responsive {
  max-height: 150px;
  max-width: 300px;
  margin-bottom: 10px;
}

.img-list-thumbnail {
  height: 42px;
}

.col-title {
  background-color:#f7f7f7;
  font-size: 18px;
  text-align: center;
  padding: 7px 10px;
  margin-top: 0;
}

.speclabel {
  position: relative;

  .delete-speclabel {
    position: absolute;
    bottom: 67px;
    left: -9px;
    color: #666;

    &:hover {
      color: #222;
    }
  }
}

.classification-card-bg {
  position: relative;
  margin-bottom: 50px;
  padding: 10px 20px;
  background-color: #ededed;

  .delete-classification-card {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #666;

    &:hover {
      color: #222;
    }
  }
}

.flag-icon-en {
  @extend .flag-icon-gb
}

.img-full-width {
  width:100%;
  max-width:100%;
}

.light_table {
  #datatable_info,#datatable_length,#datatable_paginate {display:none}
}

a {
    text-decoration: none!important;
}

.pull-right {
    float:right;
}

.hidden {display:none}

.dataTables_filter {text-align:right}
