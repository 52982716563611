// Variables
@import 'variables';
// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';

@import 'bootstrap';
// Bootstrap
// Already imported by AdminLTE
//@import '~bootstrap/scss/bootstrap';

@import "~flag-icon-css/sass/flag-icon";

@import "custom";
